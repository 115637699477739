<template>
    <div class="module">
        <a href="javascript:void(0)" class="scrollT" ref="module"></a>
        <div class="banner">
            <div class="main">
                <div class="title">DMEGC Solar<br>Module Authenticity</div>
            </div>
        </div>
        <div class="box">
            <div class="search wow fadeInUp">
                <input type="text" v-model="cptm" placeholder="Input serial number here" class="text">
                <button class="btn" @click="searchCode"></button>
            </div>
            <div class="result-main">
                <a href="javascript:void(0);" class="anchor" ref="result"></a>
                <div class="result wow fadeInUp" v-if="resultFlag === 1">
                    <div class="title">Product Information：</div>
                    <div class="list">
                        <div class="item">
                            <div class="name">Product Barcode</div>
                            <div class="num">{{infoData.cptm}}</div>
                        </div>
                        <div class="item">
                            <div class="name">Product Model</div>
                            <div class="num">{{infoData.cpxh}}</div>
                        </div>
                        <div class="item">
                            <div class="name">Product Power</div>
                            <div class="num">{{infoData.cpgl}}</div>
                        </div>
                        <div class="item">
                            <div class="name">Product Grade</div>
                            <div class="num">{{infoData.cpdjen}}</div>
                        </div>
                    </div>
                </div>
                <div class="no-result wow fadeInUp" v-if="resultFlag === 2">
                    <div class="title">Product Information：</div>
                    <div class="icon"></div>
                    <h4>Sorry, the module information you inquired was not found.</h4>
                    <div class="txt">
                        <!-- {{msg}} -->
                        1. The serial number you are checking is incorrect.<br>
                        2. The serial number you are checking is not DMEGC Solar product.<br>
                        3. The serial number you are checking should start with DM******.<br>
                        4. The module produced after Jan 1, 2024 is available in this system.<br>
                        In case of any questions, please be kindly to contact your distributor or contact DMEGC Solar Customer Service Team by hotline: +86-579-86310330 or service@dmegc.com.cn for help.
                    </div>
                    <div class="tips">For more information, please contact DMEGC Solar service team.</div>
                    <a href="javascript:void" @click="resetSearch">Back<i></i></a>
                </div>
            </div>
            <div class="info wow fadeInUp">
                The serial number is unique for each module which is composed by<br>
                some digits and alphabetsas example below.
            </div>
            <div class="tel wow fadeInUp">
                In case of any questions, please contact DMEGC service team by<br>
                <span>+ 86-579-86310330 (workday 8:30-17:00)</span> or <span>service@dmegc.com.cn</span>
            </div>
            <div class="title wow fadeInUp">Serial number example diagram：</div>
            <div class="pic wow fadeInUp">
                <img src="../../assets/img/query_pic.jpg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    import { WOW } from 'wowjs'
    import { queryCompoments } from '../../request/api'
    export default {
        name:'Module',
        metaInfo:{
            title:"DMEGC Solar-product",
            meta: [{                 // set meta
                name: 'keyWords',
                content: 'DMEGC Solar'
            },{
                name:'description',
                content:'DMEGC Solar'
            }]
        },
        data(){
            return {
                cptm:'',
                infoData:{},
                msg:'',
                resultFlag: 0,
                isMobile:false
            }
        },
        mounted(){
            this.$nextTick(() => {

                const wow = new WOW({
                    animateClass: 'animated',
                    offset: 100
                })
                wow.init();
                this.$refs.module.scrollIntoView();
            })
        },
        methods: {
            searchCode(){

                if(this.cptm === '') {

                    alert('Input serial number here')
                } else {

                    queryCompoments({cptm: this.cptm}).then(res => {
            
                        if(res.code == '00000000') {

                            this.resultFlag = 1
                            this.infoData = res.data
                            this.cptm = ''
                        } else {
                            this.resultFlag = 2
                            this.msg = res.msg
                            this.cptm = ''
                        }

                        //this.$refs.result.scrollIntoView({behavior: "smooth"});
                    })
                }
            },
            resetSearch(){

                this.cptm = ''
                this.resultFlag = 0
                this.$refs.module.scrollIntoView({behavior:'smooth'});
            }
        }
    }
</script>

<style lang="scss" scoped>
.module{
    position: relative;
    .banner{
        height: 27.0833vw;
        background: url(../../assets/img/module_banner.jpg) no-repeat center center;
        background-size: cover;
        .main{
            height: 27.0833vw;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .title{
            font-size: 2.7083vw;
            line-height: 1.1154;
            color: #fff;
            text-align: center;
            font-weight: 300;
        }
    }
    .box{
        margin: -36px auto 0;
        width: 1124px;
        position: relative;
        z-index: 10;
        padding-bottom: 110px;
    }
    .search{
        display: flex;
        justify-content: space-between;
        .text{
            display: block;
            box-sizing: border-box;
            width: calc(100% - 82px);
            height: 72px;
            background: #F2F4F8;
            border: none;
            padding: 0 40px;
            font-size: 20px;
            color: #A4AAB6;
        }
        .btn{
            display: block;
            width: 82px;
            height: 72px;
            border: none;
            background: url(../../assets/images/search_w.png) no-repeat center center #E60011;
            background-size: 26px 26px;
        }
    }
    .info{
        text-align: center;
        font-size: 20px;
        line-height: 1.5;
        color: rgba(0, 0, 0, 0.8);
        margin-top: 30px;
    }
    .tel{
        text-align: center;
        margin: 27px 0 40px;
        font-size: 20px;
        line-height: 1.5;
        color: rgba(0, 0, 0, 0.8);
        span{
            color: #E60011;
            font-weight: bold;
        }
    }
    .title{
        font-size: 25px;
        line-height: 1.92;
        text-align: center;
        color: #000;
    }
    .pic{
        width: 451px;
        margin: 6px auto 0;
        img{
            display: block;
            width: 100%;
        }

    }
    .result-main{
        position: relative;
        margin: 30px 0 68px;
    }
    .result{
        border-bottom: 1px solid #E1E6EF;
        padding-bottom: 35px;
        .title{
            text-align: left;
        }
        .list{
            margin-top: 12px;
        }
        .item{
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 90px;
            background: #F2F4F8;
            margin-bottom: 10px;
            font-size: 18px;
            color: rgba(31, 50, 67, 0.7);
            padding: 0 40px;
            line-height: 1.4;
        }
        .num{
            color: #1F3243;
            text-align: right;
            max-width: 400px;
        }
    }
    .no-result{
        border-bottom: 1px solid #E1E6EF;
        padding-bottom: 35px;
        .icon{
            width: 56px;
            height: 56px;
            background: url(../../assets/images/close.png) no-repeat center center #FF0000;
            background-size: 20px 20px;
            border-radius: 50%;
            margin: 30px auto 0;
        }
        h4{
            font-size: 25px;
            line-height: 1.92;
            color: #E60011;
            text-align: center;
            margin-top: 8px
        }
        .txt{
            font-size: 20px;
            line-height: 1.6;
            color: rgba(0, 0, 0, 0.6);
            margin: 48px auto 0;
            width: 685px;
        }
        .tips{
            font-size: 25px;
            line-height: 1.92;
            color: #000000;
            text-align: center;
            margin-top: 48px;
        }
        a{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 260px;
            height: 50px;
            box-sizing: border-box;
            padding: 0 30px;
            font-size: 16px;
            color: #fff;
            background: #E60011;
            margin: 52px auto 0;
        }
        i{
            display: block;
            width: 24px;
            height: 24px;
            background: url(../../assets/images/arrowR.png) no-repeat;
            background-size: cover;
        }
    }
}
@media screen and (max-width: 750px) {
    .module{
        .banner{
            height: 5rem;
        }
        .box{
            width: 92%;
            padding-bottom: .8rem;
            margin-top: -.5rem;
        }
        .search{
            .text{
                width: calc(100% - 1.2rem);
                height: 1rem;
                padding: 0 .3rem;
                font-size: .28rem;
            }
            .btn{
                width: 1.2rem;
                height: 1rem;
                background-size: .52rem .52rem;
            }
        }
        .info{
            font-size: .28rem;
            margin-top: .6rem;
        }
        .tel{
            font-size: .28rem;
            margin: .4rem 0 .6rem;
        }
        .title{
            font-size: .36rem;
        }
        .pic{
            width: 4.5rem;
            margin-top: .2rem;
        }
        .result-main{
            margin: .4rem 0 .8rem;
        }
        .result{
            padding-bottom: .6rem;
            .list{
                margin-top: .2rem;
            }
            .item{
                height: 1rem;
                margin-bottom: .2rem;
                padding: 0 .3rem;
                font-size: .28rem;
            }
            .num{
                max-width: 4rem;
            }
        }
        .no-result{
            padding-bottom: .6rem;
            .icon{
                width: 1.12rem;
                height: 1.12rem;
                background-size: .4rem .4rem;
                margin-top: .6rem;
            }
            h4{
                font-size: .36rem;
                margin-top: .3rem;
            }
            .txt,.tips{
                font-size: .28rem;
                width: 100%;
                margin-top: .6rem;
            }
            a{
                width: 4.2rem;
                height: .8rem;
                font-size: .3rem;
                padding: 0 .6rem;
                margin-top: .6rem;
            }
            i{
                width: .48rem;
                height: .48rem;
            }
        }
    }
}
</style>